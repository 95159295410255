<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-money"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="isEdit && !spinners.loading">
          : <h3 class="d-inline"><CBadge color="secondary">{{ this.itemName }}</CBadge></h3>
        </template>
      </h5>

      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'Vouchers' }">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody>
      <form-summary
        v-if="!isValid"
        class="form-errors alert alert-danger"
        :validator="$v.form"
        :attributes="attributes"
      />

      <CForm class="voucher-form" v-if="!spinners.loading">
        <CTabs class="tab-menu" :active-tab.sync="activeTab" variant="pills" :vertical="{ navs: 'col-xl-2 col-md-3', content: 'col-xl-10 col-md-9' }">
          <CTab active>
            <template slot="title">
              <CIcon name="cil-task"/> Basics
            </template>

            <div class="d-flex flex-column">
              <div class="d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  label="Code:"
                  placeholder="Code"
                  :lazy="false"
                  :value.sync="$v.form.code.$model"
                  :isValid="checkIfValid('code')"
                />
                <CInput
                  class="w-100"
                  label="Name:"
                  placeholder="Name"
                  :lazy="false"
                  :value.sync="$v.form.name.$model"
                />
              </div>

              <div class="d-flex flex-column flex-md-row">
                <CTextarea
                  class="w-100"
                  rows="3"
                  label="Description:"
                  placeholder="Description"
                  :value.sync="$v.form.description.$model"
                />
              </div>

              <div class="d-flex flex-column flex-md-row">
                <div class="d-flex w-100 mr-2">
                  <div class="form-group mr-3">
                    <label class="d-block" style="font-weight: 500">Is fixed:</label>
                    <CSwitch
                      :checked.sync="$v.form.is_fixed.$model"
                      labelOn="YES"
                      labelOff="NO"
                      color="success"
                      shape="pill"
                      size="lg"
                    />
                  </div>

                  <CInput
                    class="w-100"
                    label="Discount amount:"
                    placeholder="0.00"
                    :value.sync="$v.form.discount_amount.$model"
                    type="number"
                    step="0.01"
                    pattern="^\d+(?:\.\d{1,2})?$"
                  >
                    <template v-if="form.is_fixed === true" #prepend-content><h4 class="mb-0">€</h4></template>
                    <template v-else #append-content><h4 class="mb-0">%</h4></template>
                  </CInput>
                </div>

                <CInput
                  class="w-100"
                  label="Min. value:"
                  placeholder="0.00"
                  :value.sync="$v.form.minvalue.$model"
                  type="number"
                  step="0.01"
                  pattern="^\d+(?:\.\d{1,2})?$"
                >
                  <template #prepend-content><h4 class="mb-0">€</h4></template>
                </CInput>
              </div>

              <div class="d-flex flex-column flex-md-row">
                <CInput
                  class="w-100 mr-2"
                  type="date"
                  label="Start:"
                  :min="new Date().toISOString().slice(0,10)"
                  :value.sync="$v.form.starts_at.$model"
                />

                <CInput
                  class="w-100"
                  type="date"
                  label="Expire:"
                  :min="new Date().toISOString().slice(0,10)"
                  :value.sync="$v.form.expires_at.$model"
                />
              </div>

              <div class="d-flex flex-column flex-md-row">
                <CInput
                  type="number"
                  class="w-100 mr-2"
                  label="Max. usage:"
                  placeholder="0"
                  :value.sync="$v.form.max_uses.$model"
                />

                <CInput
                  type="number"
                  class="w-100"
                  label="Max. usage per user:"
                  placeholder="0"
                  :value.sync="$v.form.max_uses_user.$model"
                />
              </div>

              <div class="d-flex flex-column flex-md-row">
                <div class="form-group w-100 mr-2">
                  <label class="d-block">User:</label>
                  <v-select
                    :placeholder="users.length == 0 ? 'Search for a user..' : 'Select or search for a user..'"
                    v-model="$v.form.user_id.$model"
                    :options="users"
                    :reduce="c => c.id"
                    :searchable="true"
                    :loading="spinners.searching && users.length == 0"
                    @search="searchUsers"
                  >
                    <template v-slot:option="user">
                      <div class="d-flex align-items-center">
                        <div class="c-avatar mr-2">
                          <img :src="user.image_icon || user.gravatar" class="c-avatar-img">
                        </div>
                        <span class="small"><strong>{{ user.first_name }}</strong> #{{ user.id }}  ({{ user.email }})</span>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="user">
                      {{ user.first_name }} #{{ user.id }}
                    </template>
                    <span slot="no-options">Type to search users..</span>
                  </v-select>
                </div>

                <CInput
                  type="number"
                  class="w-100"
                  label="Valid until the user:"
                  placeholder="0"
                  :value.sync="$v.form.untill_user_id.$model"
                />
              </div>

              <div class="d-flex flex-column flex-md-row">
                <div class="form-group w-100">
                  <label class="d-block">Restaurant:</label>
                  <v-select
                    :reduce="c => c.value"
                    placeholder="Please select.."
                    :searchable="true"
                    v-model="$v.form.resto_id.$model"
                    :options="restaurants"
                  />
                </div>
              </div>
            </div>
          </CTab>
        </CTabs>
      </CForm>

      <CSpinner v-else color="primary" />
    </CCardBody>

    <CCardFooter class="sticky-bottom">
      <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
        <CButton
          color="primary"
          :disabled="spinners.loading || spinners.btnSubmit"
          @click="submit()"
        >
          <CSpinner v-if="spinners.btnSubmit" size="sm" /> Submit
        </CButton>
      </div>
    </CCardFooter>

    <mc-spinner :opacity="0.8" v-show="spinners.btnSubmit" />
  </CCard>

</template>


<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate"
import { required, minValue } from "vuelidate/lib/validators"
import _debounce from 'lodash/debounce';

export default {
  name: 'Voucher',
  components: {

  },

  data() {
    return {
      activeTab: 0,
      form: {},
      isEdit: false,
      spinners: {
        loading: false,
        searching: false,
        btnSubmit: false,
      },

      itemName: '',
      unsavedChanges: false,

      restaurants: [],
      users: [],

      // Vuelidate-error-extractor
      attributes: {
        code: 'Voucher code',
        name: 'Voucher name',
        discount_amount: 'Discount amount',
        minvalue: 'Min. value',
        max_uses: 'Max. usage',
      },
    }
  },

  mixins: [validationMixin],
  validations: {
    form: {
      code: { required },
      name: { required },
      description: {},
      discount_amount: { required },
      minvalue: {
        minValue: value => value === null || value === undefined || value === '' || minValue(10)(value)
      },
      max_uses: { required, minValue:minValue(1) },
      max_uses_user: {},
      is_fixed: {},
      untill_user_id: {},
      user_id: {},
      resto_id: {},
      starts_at: {},
      expires_at: {},
    }
  },

  // Route koruması için
  beforeRouteLeave (to, from , next) {
    if (this.unsavedChanges === true) {
      const answer = window.confirm('Do you really want to leave? You have unsaved changes!')
      this.unsavedChanges = !answer;
      next(answer) // answer: true or false
    } else {
      next()
    }
  },

  beforeMount() {
    this.searchUsers = _debounce(async (val) => {
      await this.getUsers(val);
    }, 500);
  },

  async mounted() {
    this.activeTab = this.$route.query.tab ? parseInt(this.$route.query.tab) : 0;

    // F5 veya başka bir siteye gitmeden önce 'Yaptığınız değişiklikler kaydedilmemiş olabilir.' koruması için
    window.onbeforeunload = () => this.unsavedChanges === true ? true : null;

    this.$watch('form', (newV, oldV) => {
      const initialState = sessionStorage.getItem(`voucher-form`);
      const newState = JSON.stringify(this.transformedForm);

      this.unsavedChanges = initialState !== newState;
    }, { deep: true });

    await this.getRestaurants();
    this.form = await this.getForm(this.itemId)
    sessionStorage.setItem(`voucher-form`, JSON.stringify(this.transformedForm));
  },

  computed: {
    ...mapGetters(["errors"]),
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    itemId() { return Number(this.$route.params.id) || null },
    transformedForm() {
      return this.$globalFunc.replaceEmptyStringsWithNull(this.form);
    }
  },

  methods: {
    checkIfValid (fieldName) {
      const fields = fieldName.split(".");
      const field = fields.length > 1
        ? this.$v.form[fields[0]][fields[1]]
        : this.$v.form[fieldName];

      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === "");
    },

    async submit() {
      window.scrollTo(0, 0);

      if (!this.isValid) {
        this.validate();
        this.activeTab = 0;
        return;
      }

      if (this.isEdit) {
        await this.update()
      } else {
        await this.store()
      }
    },

    async update() {
      this.spinners.btnSubmit = true

      await this.$axios.put(this.$backend.VOUCHERS.UPDATE.replace("{id}", this.itemId), this.transformedForm)
        .then(({ data }) => {
          this.itemName = data.data.name
          this.$toast.success(`The voucher is updated.`);
          this.unsavedChanges = false
        })
        .catch((error) => {
          this.spinners.btnSubmit = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.spinners.btnSubmit = false
        });
    },

    async store() {
      this.spinners.btnSubmit = true

      await this.$axios.post(this.$backend.VOUCHERS.STORE, this.transformedForm)
        .then(({ data }) => {
          this.itemName = data.data.name
          this.$toast.success(`The voucher is created.`);
          this.unsavedChanges = false

          setTimeout(() => {
            this.$router.push({ name: 'Vouchers' })
          }, 3000)
        })
        .catch((error) => {
          this.spinners.btnSubmit = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        })
        .finally(() => {
          this.spinners.btnSubmit = false
        });
    },

    validate() {
      this.$v.$touch()
    },

    async getForm (id = null) {
      var form = {
        code: null,
        name: null,
        description: null,
        discount_amount: null,
        minvalue: null,
        max_uses: '1',
        max_uses_user: null,
        is_fixed: true,
        untill_user_id: null,
        user_id: null,
        resto_id: null,
        starts_at: null,
        expires_at: null,
      };

      if(id) {
        this.isEdit = true
        this.spinners.loading = true

        await this.$axios.get(this.$backend.VOUCHERS.SHOW.replace("{id}", this.itemId))
          .then(({data}) => {
            const voucher = data.data;
            this.itemName = voucher.name

            form.code = voucher.code;
            form.name = voucher.name;
            form.description = voucher.description;
            form.discount_amount = voucher.discount_amount;
            form.minvalue = voucher.minvalue;
            form.max_uses = voucher.max_uses;
            form.max_uses_user = voucher.max_uses_user;
            form.is_fixed = voucher.is_fixed;
            form.untill_user_id = voucher.untill_user_id;
            form.user_id = voucher.user_id;
            form.resto_id = voucher.resto_id;
            form.starts_at = voucher.starts_at ? new Date(voucher.starts_at).toISOString().split('T')[0]: '';
            form.expires_at = voucher.expires_at ? new Date(voucher.expires_at).toISOString().split('T')[0]: '';

            if (voucher.user) {
              this.users.push(voucher.user);
            }

            this.spinners.loading = false;
          })
          .catch((error) => {
            this.$toast.error(`${error.response.data.message}`);
            setTimeout(() => { this.$router.push({ name: 'Vouchers' }) }, 3000);
          })
          .finally(() => { this.unsavedChanges = false })
      }

      return form;
    },

    async getRestaurants() {
      const storedRestaurants = sessionStorage.getItem('restaurants');

      if (storedRestaurants) {
        this.restaurants = JSON.parse(storedRestaurants);
      } else {
        try {
          const response = await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME);
          this.restaurants = response.data;
          sessionStorage.setItem('restaurants', JSON.stringify(this.restaurants));
        } catch (error) {
          this.restaurants = [];
        }
      }
    },
    async getUsers(val) {
      if (val.length < 3) {
        return;
      }

      this.spinners.searching = true;
      try {
        const response = await this.$axios.get(this.$backend.USERS.GET_ALL, {
          params: {
            search: val,
            'get-relations': false,
            'except-couriers': false,
          }
        });
        this.users = response.data.data.map((el) => {
          return { ...el, label: `i#${el.id} ${el.first_name} ${el.email}` };
        });
      } catch (error) {
        this.users = [];
      } finally {
        this.spinners.searching = false;
      }
    },
  }
}
</script>

<style>
.tab-menu > div:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  /* border-bottom: 1px solid #d8dbe0; */
}

form.voucher-form .form-control,
form.voucher-form option,
form.voucher-form .vs__search,
form.voucher-form .vs__dropdown-menu,
form.voucher-form .vs__selected {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}
form.voucher-form .vs__dropdown-toggle {
  padding: 2px 0 6px;
}
form.voucher-form label.c-switch {
  margin-top: 0.3rem;
}
</style>
